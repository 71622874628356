<template>
  <v-container grid-list-md fluid>
    <v-layout align-center>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            text
            icon
            :to="{ name: 'rodo' }"
            color="primary"
            exact
            v-on="on"
          >
            <fa-icon icon="arrow-left"></fa-icon>
          </v-btn>
        </template>
        <span>{{ $t("global.back") }}</span>
      </v-tooltip>
      <v-subheader>
        <label class="title">{{ $t("tasks.title") }}</label>
      </v-subheader>
    </v-layout>
    <TasksTable></TasksTable>
  </v-container>
</template>

<script>
import TasksTable from "../components/TasksTable";

export default {
  name: "Tasks",
  components: { TasksTable }
};
</script>
<style lang="scss" scoped>
.v-subheader {
  padding-left: 0px;
  .title {
    color: var(--v-primary-base);
    font-size: 16px !important;
  }
}
</style>
