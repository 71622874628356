export class Header {
  constructor(text, value, width, sortable) {
    this.text = text;
    this.value = value;
    this.sortable = sortable || false;
    this.align = "left";
    this.width = width;
  }
}

export class ContactFilter {
  constructor(firstname, surname, phone, mail) {
    this.firstname = firstname;
    this.surname = surname;
    this.phone = phone;
    this.mail = mail;
  }
  validate() {
    // ŁW: Nie chcemy pustych wartości dla wszystkich propsów, ani żadnego propsa z długością < 3
    var filteredLengthArray = Object.values(this)
      .filter(el => el && typeof el !== "function")
      .map(el => (el + "").length);
    return filteredLengthArray[0]
      ? filteredLengthArray.every(len => len >= 3)
      : false;
  }
}

function getLength(inputStr) {
  return !inputStr ? 0 : inputStr.length;
}
