import axios from "../httpConfig/http";
import store from "../store/index";

const urlApi = "api/rodo";

let login = () => {
  return store.state.user.login;
};
export default {
  Search(filters) {
    return axios.post(`${urlApi}`, {
      data: filters,
      userName: login()
    });
  },
  GetContactHistory(foreignKey, domain, contactFilters) {
    return axios.get(`${urlApi}/contact`, {
      params: {
        foreignKey: foreignKey,
        domainSystemName: domain,
        userName: login(),
        firstName: contactFilters.firstname,
        surname: contactFilters.surname,
        phone: contactFilters.phone,
        mail: contactFilters.mail
      }
    });
  },
  GetQuarantine() {
    return axios.get(`${urlApi}/quarantine`);
  },
  SetQuarantine(contacts) {
    return axios.post(`${urlApi}/quarantine`, {
      data: contacts,
      userName: login()
    });
  },
  UnsetQuarantine(contacts) {
    return axios.post(`${urlApi}/UnsetQuarantine`, {
      data: contacts,
      userName: login()
    });
  },
  ExportContact(contact) {
    axios
      .get(`api/file/contact/export`, {
        params: contact
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${contact.id}.xml`);
        link.click();
      });
  },

  DeleteContacts(contacts) {
    return axios.delete(`${urlApi}`, {
      data: {
        data: contacts,
        userName: login()
      }
    });
  }
};
