<template>
  <v-layout column>
    <v-layout row wrap>
      <v-flex xs12>
        <v-data-table
          v-model="selected"
          :loading="loading"
          :headers="headers"
          :items="items"
          item-key="id"
          show-select
        >
          <v-progress-linear slot="progress" indeterminate></v-progress-linear>
          <template slot="items" slot-scope="props">
            <tr :active="props.selected">
              <td @click.stop>
                <v-checkbox
                  v-model="props.selected"
                  hide-details
                  color="primary"
                ></v-checkbox>
              </td>
              <td>{{ props.item.firstName }}</td>
              <td>{{ props.item.surname }}</td>
              <td>{{ props.item.phone }}</td>
              <td>{{ props.item.mail }}</td>
              <td>{{ props.item.domainSystemName }}</td>
              <td>{{ props.item.insertTime | dateTimeFilter }}</td>
              <td>{{ props.item.expirationDate | dateTimeFilter }}</td>
              <td>{{ $t("tasks.taskMessage") }}</td>
            </tr>
          </template>
          <template slot="pageText" slot-scope="props"></template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout class="text-xs-right" pt-5>
      <v-flex>
        <ConfirmModal :disabled="!selected[0]" @confirm-modal="confirmModal">
          <template v-slot:default="{ on }">
            <v-btn
              v-if="dataRemoveAllowed"
              class="light-button"
              :disabled="!selected[0]"
              v-on="on"
              >{{ $t("global.remove") }}</v-btn>
          </template>
        </ConfirmModal>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import { Header } from "../common/Common.js";
import RodoService from "../services/RodoService.js";
import { restrictions } from "../common/RestrictionsCode";
import ConfirmModal from "./ConfirmModal";
import { mapState, mapActions } from "vuex";
import { EmitSuccess } from "../event-bus";
import dateTimeFilters from "../mixins/date-time-filters";

export default {
  name: "TasksTable",
  components: { ConfirmModal },
  mixins: [dateTimeFilters],
  data() {
    return {
      loading: false,
      headers: [
        new Header(this.$t("rodo.name")),
        new Header(this.$t("rodo.surname")),
        new Header(this.$t("rodo.phone")),
        new Header(this.$t("rodo.mail")),
        new Header(this.$t("rodo.system")),
        new Header(this.$t("tasks.expirationDate")),
        new Header(this.$t("tasks.insertDate")),
        new Header(this.$t("tasks.content"))
      ],
      selected: [],
      dataRemoveAllowed: this.$can("hasRestriction", {
        code: restrictions.dataDelete
      })
    };
  },
  computed: {
    ...mapState({
      items: state => state.expirationContacts
    })
  },
  methods: {
    confirmModal() {
      RodoService.DeleteContacts(this.selected).then(response => {
        if (response.isValid) {
          EmitSuccess(this.$t("global.operationSuccess"));
          this.getTasks();
        }
      });
    },
    ...mapActions({ getTasks: "SET_TASKS" })
  }
};
</script>
