<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      :max-width="width"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on }">
        <slot v-bind:on="on"></slot>
      </template>
      <v-card>
        <v-card-title class="headline" primary-title>{{
          modalHeader
        }}</v-card-title>
        <v-card-text>{{ modalText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onConfirm">{{
            $t("global.confirm")
          }}</v-btn>
          <v-btn color="primary" text @click="onCancel">{{
            $t("global.cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "ConfirmModal",
  props: {
    header: {
      type: String
    },
    value: {
      type: String
    },
    width: {
      type: Number,
      default: 390
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    modalHeader() {
      if (this.header) {
        return this.header;
      }
      return this.$t("global.confirm");
    },
    modalText() {
      if (this.value) {
        return this.value;
      }
      return this.$t("global.confirmText");
    }
  },
  methods: {
    onClose() {
      this.dialog = false;
    },
    onCancel() {
      this.$emit("cancel-modal");
      this.onClose();
    },
    onConfirm() {
      this.$emit("confirm-modal");
      this.onClose();
    }
  }
};
</script>
<style scoped>
.v-card__text {
  margin-top: 24px;
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
